body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background: #000;
  font-family: 'Arial', sans-serif;
  overflow: hidden;
}

.neon-text {
  font-size: 4rem;
  /*color: #fff;*/
  /*text-shadow: 30px -20px 10px #D900FFFF, 30px 20px 10px #D900FFFF;*/
  animation: glow 1.5s infinite alternate;
}

@keyframes glow {
  0% {
    text-shadow: 0 0 5px #D900FFFF, 0 0 10px #D900FFFF, 0 0 20px #D900FFFF, 0 0 40px #D900FFFF, 0 0 80px #D900FFFF;
  }
  100% {
    text-shadow: 0 0 10px #00d4ff, 0 0 20px #00d4ff, 0 0 40px #00d4ff, 0 0 80px #00d4ff, 0 0 160px #00d4ff;
  }
}